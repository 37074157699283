<script setup>

import { onMounted, onUnmounted, ref, computed, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { toast } from 'vue-sonner'
import { v4 as uuidv4 } from 'uuid';

import { defineStore } from 'pinia';
import { onBeforeEnter, onEnter, onLeave } from '@/utils/animations';

import { post } from '@/utils/http';

import { getDecodedToken } from '@/utils/auth';
import { initials } from '@/lib/utils';
import { beep } from '@/utils/sound';

import { LoaderCircle, Bot, Sparkles, Files } from 'lucide-vue-next';

const router = useRouter();
const { t } = useI18n();

const props = defineProps({
    url: {
        type: String,
        required: true
    },
    filters: {
        type: Object,
        required: true
    },
    isOpen: {
        type: Boolean,
        required: false,
        default: false
    },
    showMainSearch: {
        type: Boolean,
        required: false,
        default: true
    }
});

const emits = defineEmits(['update:open']);

const useChatStore = defineStore({
    id: uuidv4(),
    state: () => ({
        conversationId: null,
        history: []
    })
})

const useCitationsStore = defineStore({
    id: uuidv4(),
    state: () => {
        return {
            citations: [],
            url: null
        }
    },
})

const chatStore = useChatStore();
const citationsStore = useCitationsStore();

const isOpen = ref(props.isOpen);

onMounted(() => {
    chatStore.$reset();
    citationsStore.$reset();
});

onUnmounted(() => {
    chatStore.$reset();
    citationsStore.$reset();
});

const currentUserInitials = computed(() => {
    const { firstName, lastName } = getDecodedToken();
    return initials(`${firstName} ${lastName}`);
});

const chatText = ref(router.currentRoute.value.query.search || '');
const isPostingChat = ref(false);

const chatRef = ref(null);

const chatWithData = async () => {

    const tempText = chatText.value;
    chatText.value = '';

    isPostingChat.value = true;

    const newMessage = {
        userQuestion: tempText,
        answer: null,
        citations: []
    };

    chatStore.history.push(newMessage);

    try {
        const { data } = await post(props.url, {
            payload: {
                userQuestion: tempText,
                conversationId: chatStore.conversationId,
                answer: null,
            },
            filter: props.filters ?? {},
        });

        if (!data || !data.answer) {
            toast(t('common.noAnswerFound'), {
                description: '',
                action: {
                    label: t('common.close'),
                },
            })

            chatStore.history.pop();
            chatText.value = tempText;
            return;
        }

        if (!isOpen.value) {
            isOpen.value = true;
        }

        chatStore.history[chatStore.history.length - 1] = data;
        chatStore.conversationId = data.conversationId;
        chatText.value = '';

        beep();
        await scroll();

    } catch (error) {
        chatText.value = tempText;

        toast(t('common.noAnswerFound'), {
            description: '',
            action: {
                label: t('common.close'),
            },
        });

    } finally {
        isPostingChat.value = false;
    }
};

const scroll = async () => {
    const element = chatRef?.value?.$el?.children[0];

    if (element) {
        await nextTick();
        element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
}

const showCitations = (citations) => {
    citationsStore.citations = citations;
}

watch(() => isOpen.value, (value) => {
    if (!value) {
        chatStore.$reset();
        citationsStore.$reset();
    }

    emits('update:open', value);
});

watch(() => props.isOpen, (value) => {
    isOpen.value = value;
});


</script>

<template>

    <Transition mode="out-in">
        <div v-if="props.showMainSearch" class="relative w-full items-center">
    
            <Input id="search" type="text" :placeholder="t('common.search')" class="pr-12 pl-5" :readonly="isPostingChat" @keydown.enter="chatWithData" v-model="chatText" />
    
            <Button variant="ghost" @click="chatWithData" :disabled="isPostingChat || !chatText" class="absolute end-0 inset-y-[0px] flex items-center justify-center hover:cursor-pointer">
                <Transition mode="out-in">
                    <LoaderCircle v-if="isPostingChat" class="animate-spin h-4 w-4" />
                    <Sparkles v-else class="size-4 text-muted-foreground" />
                </Transition>
            </Button>
    
        </div>
    </Transition>

    <Sheet :open="isOpen" @update:open="o => isOpen = o">

        <SheetContent side="right" class="w-[800px] sm:max-w-full">

            <div class="relative w-full items-center my-4">

                <Input v-focus id="search" type="text" :placeholder="t('common.search')" class="pr-12 pl-5" :readonly="isPostingChat" @keydown.enter="chatWithData" v-model="chatText" />

                <Button variant="ghost" @click="chatWithData" :disabled="isPostingChat || !chatText" class="absolute end-0 inset-y-[0px] flex items-center justify-center hover:cursor-pointer">
                    <Transition mode="out-in">
                        <LoaderCircle v-if="isPostingChat" class="animate-spin h-4 w-4" />
                        <Sparkles v-else class="size-4 text-muted-foreground" />
                    </Transition>
                </Button>

            </div>

            <Transition mode="out-in">
                <div v-if="chatStore.history.length > 0">
                    <div id="container">

                        <ScrollArea class="h-[92dvh]" ref="chatRef">
                            <TransitionGroup name="chat" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                                <div v-for="(item, index) in chatStore.history" :key="index" class="flex flex-col w-full my-2 px-2">
                                    <div :id="`${index}-user-message`" v-if="item.userQuestion" class="flex flex-row gap-2 justify-end mb-2">
                                        <div class="self-stretch bg-sky-400 text-foreground p-3 rounded-3xl text-start">
                                            <p>{{ item.userQuestion }}</p>
                                        </div>
                                        <div class="flex-shrink-0 self-end">
                                            <Avatar>
                                                <AvatarFallback>{{ currentUserInitials }}</AvatarFallback>
                                            </Avatar>
                                        </div>
                                    </div>
                                    <div :id="`${index}-bot-message`" v-if="item.answer" class="flex flex-row gap-2 justify-start mb-2">
                                        <div class="flex-shrink-0 self-end">
                                            <Avatar>
                                                <AvatarFallback>
                                                    <Bot class="w-6 h-6" />
                                                </AvatarFallback>
                                            </Avatar>
                                        </div>
                                        <div class="self-stretch bg-slate-800 text-background p-3 rounded-3xl text-start flex flex-row">
                                            <MarkdownRenderer :content="item.answer" />
                                        </div>
                                    </div>
                                </div>
                            </TransitionGroup>
                        </ScrollArea>

                    </div>
                </div>
            </Transition>
        </SheetContent>
    </Sheet>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>