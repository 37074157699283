import Layout from '@/views/_shared/Layouts/Layout.vue';

import { Icon } from '@iconify/vue';

import { Checkbox } from '@/components/ui/checkbox';
import { Toaster } from '@/components/ui/sonner'
import { Button } from '@/components/ui/button/';
import { Input }from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Badge } from '@/components/ui/badge';
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea';
import { Separator }from '@/components/ui/separator';
import { Progress } from '@/components/ui/progress';
import { Skeleton } from '@/components/ui/skeleton';
import { Calendar } from '@/components/ui/calendar';

import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';

import { Card, CardHeader, CardContent, CardTitle, CardFooter } from '@/components/ui/card';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

import { Tooltip, TooltipProvider, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'

import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';

import { AlertDescription, AlertTitle, Alert } from '@/components/ui/alert'

import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog'

import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog';

import { Drawer, DrawerContent, DrawerTitle, DrawerDescription, DrawerFooter, DrawerTrigger } from '@/components/ui/drawer';

import { ScrollArea } from '@/components/ui/scroll-area';

import { Pagination, PaginationEllipsis, PaginationFirst, PaginationLast, PaginationList, PaginationListItem, PaginationNext, PaginationPrev } from '@/components/ui/pagination'

import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'

import { Avatar, AvatarFallback } from '@/components/ui/avatar';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger, DropdownMenuSeparator } from '@/components/ui/dropdown-menu';

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'

import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar';

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator, BreadcrumbPage } from '@/components/ui/breadcrumb';

import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@/components/ui/tags-input'

import { NumberField, NumberFieldContent, NumberFieldDecrement, NumberFieldIncrement, NumberFieldInput } from '@/components/ui/number-field'

import { Stepper, StepperDescription, StepperItem, StepperSeparator, StepperTitle, StepperTrigger } from '@/components/ui/stepper'

import AnimatedList from '@/views/_shared/Components/AnimatedList.vue';
import Paging from '@/views/_shared/Components/Paging.vue';

import AIButton from '@/views/_shared/Components/Inputs/AIButton.vue';
import ProcessingButton from '@/views/_shared/Components/Inputs/ProcessingButton.vue';

import Dropdown from '@/views/_shared/Components/Inputs/Dropdown.vue';
import MultiDropdown from '@/views/_shared/Components/Inputs/MultiDropdown.vue';

import ConfirmClose from '@/views/_shared/Components/ConfirmClose.vue';
import ConfirmDelete from '@/views/_shared/Components/ConfirmDelete.vue';
import Confirm from '@/views/_shared/Components/Confirm.vue';

import Indicator from '@/views/_shared/Components/Indicator.vue';
import Pulse from '@/views/_shared/Components/Pulse.vue';

import CheckCard from '@/views/_shared/Components/CheckCard.vue';
import MarkdownRenderer from '@/views/_shared/Components/MarkdownRenderer.vue';
import Modal from '@/views/_shared/Components/Modal.vue';

import Number from '@/views/_shared/Components/Inputs/Number.vue';
import DatePicker from '@/views/_shared/Components/Inputs/DatePicker.vue';

import CommentDisplay from '@/views/_shared/Components/Comments/CommentDisplay.vue';
import CommentInput from '@/views/_shared/Components/Comments/CommentInput.vue';

import Search from '@/views/_shared/Components/Search/Search.vue';

import VoiceRecorder from '@/views/_shared/Components/Recorders/VoiceRecorder.vue';
import CameraRecorder from '@/views/_shared/Components/Recorders/CameraRecorder.vue';
import ScreenRecorder from '@/views/_shared/Components/Recorders/ScreenRecorder.vue';

import ComponentButton from '@/views/_shared/Components/Elements/ComponentButton.vue';
import ComponentAI from '@/views/_shared/Components/Elements/ComponentAI.vue';
import ComponentElement from '@/views/_shared/Components/Elements/ComponentElement.vue';
import ComponentInput from '@/views/_shared/Components/Elements/ComponentInput.vue';

import ElementForm from '@/views/_shared/Components/Elements/ElementForm.vue';
import ElementValue from '@/views/_shared/Components/Elements/ElementValue.vue';

import ValueDisplay from '@/views/_shared/Components/Elements/ValueDisplay.vue';
import ValueInput from '@/views/_shared/Components/Elements/ValueInput.vue';

import TimeAgo from '@/views/_shared/Components/TimeAgo.vue';
import Crumpets from '@/views/_shared/Components/Crumpets.vue';
import AlinorLogo from '@/views/_shared/Icons/AlinorLogo.vue';

import SubscriptionSwitcher from '@/views/_shared/Components/SubscriptionSwitcher.vue';

export function importComponents(app) {
    app.component('Layout', Layout);

    app.component('Icon', Icon);

    app.component('Toaster', Toaster);
    app.component('Checkbox', Checkbox);
    app.component('Button', Button);
    app.component('Input', Input);
    app.component('Label', Label);
    app.component('Badge', Badge);
    app.component('Switch', Switch);
    app.component('Textarea', Textarea);
    app.component('Separator', Separator);
    app.component('Progress', Progress);
    app.component('Skeleton', Skeleton);
    app.component('Calendar', Calendar);

    app.component('HoverCard', HoverCard);
    app.component('HoverCardContent', HoverCardContent);
    app.component('HoverCardTrigger', HoverCardTrigger);

    app.component('Card', Card);
    app.component('CardHeader', CardHeader);
    app.component('CardContent', CardContent);
    app.component('CardTitle', CardTitle);
    app.component('CardFooter', CardFooter);

    app.component('Popover', Popover);
    app.component('PopoverContent', PopoverContent);
    app.component('PopoverTrigger', PopoverTrigger);

    app.component('Tooltip', Tooltip);
    app.component('TooltipContent', TooltipContent);
    app.component('TooltipTrigger', TooltipTrigger);
    app.component('TooltipProvider', TooltipProvider);

    app.component('Sheet', Sheet);
    app.component('SheetContent', SheetContent);
    app.component('SheetTrigger', SheetTrigger);

    app.component('Alert', Alert);
    app.component('AlertTitle', AlertTitle);
    app.component('AlertDescription', AlertDescription);

    app.component('AlertDialog', AlertDialog);
    app.component('AlertDialogAction', AlertDialogAction);
    app.component('AlertDialogCancel', AlertDialogCancel);
    app.component('AlertDialogContent', AlertDialogContent);
    app.component('AlertDialogDescription', AlertDialogDescription);
    app.component('AlertDialogFooter', AlertDialogFooter);
    app.component('AlertDialogHeader', AlertDialogHeader);
    app.component('AlertDialogTitle', AlertDialogTitle);

    app.component('Dialog', Dialog);
    app.component('DialogTitle', DialogTitle);
    app.component('DialogDescription', DialogDescription);
    app.component('DialogContent', DialogContent);
    app.component('DialogFooter', DialogFooter);

    app.component('DrawerTrigger', DrawerTrigger);
    app.component('Drawer', Drawer);
    app.component('DrawerTitle', DrawerTitle);
    app.component('DrawerDescription', DrawerDescription);
    app.component('DrawerContent', DrawerContent);
    app.component('DrawerFooter', DrawerFooter);

    app.component('ScrollArea', ScrollArea);

    app.component('Pagination', Pagination);
    app.component('PaginationEllipsis', PaginationEllipsis);
    app.component('PaginationFirst', PaginationFirst);
    app.component('PaginationLast', PaginationLast);
    app.component('PaginationList', PaginationList);
    app.component('PaginationListItem', PaginationListItem);
    app.component('PaginationNext', PaginationNext);
    app.component('PaginationPrev', PaginationPrev);

    app.component('Command', Command);
    app.component('CommandDialog', CommandDialog);
    app.component('CommandEmpty', CommandEmpty);
    app.component('CommandGroup', CommandGroup);
    app.component('CommandInput', CommandInput);
    app.component('CommandItem', CommandItem);
    app.component('CommandList', CommandList);

    app.component('Avatar', Avatar);
    app.component('AvatarFallback', AvatarFallback);

    app.component('Tabs', Tabs);
    app.component('TabsContent', TabsContent);
    app.component('TabsList', TabsList);
    app.component('TabsTrigger', TabsTrigger);

    app.component('Table', Table);
    app.component('TableBody', TableBody);
    app.component('TableCaption', TableCaption);
    app.component('TableCell', TableCell);
    app.component('TableHead', TableHead);
    app.component('TableHeader', TableHeader);
    app.component('TableRow', TableRow);

    app.component('DropdownMenu', DropdownMenu);
    app.component('DropdownMenuContent', DropdownMenuContent);
    app.component('DropdownMenuItem', DropdownMenuItem);
    app.component('DropdownMenuLabel', DropdownMenuLabel);
    app.component('DropdownMenuTrigger', DropdownMenuTrigger);
    app.component('DropdownMenuSeparator', DropdownMenuSeparator);

    app.component('FormControl', FormControl);
    app.component('FormDescription', FormDescription);
    app.component('FormField', FormField);
    app.component('FormItem', FormItem);
    app.component('FormLabel', FormLabel);
    app.component('FormMessage', FormMessage);

    app.component('Menubar', Menubar);
    app.component('MenubarContent', MenubarContent);
    app.component('MenubarItem', MenubarItem);
    app.component('MenubarMenu', MenubarMenu);
    app.component('MenubarTrigger', MenubarTrigger);

    app.component('Breadcrumb', Breadcrumb);
    app.component('BreadcrumbItem', BreadcrumbItem);
    app.component('BreadcrumbLink', BreadcrumbLink);
    app.component('BreadcrumbList', BreadcrumbList);
    app.component('BreadcrumbSeparator', BreadcrumbSeparator);
    app.component('BreadcrumbPage', BreadcrumbPage);

    app.component('TagsInput', TagsInput);
    app.component('TagsInputInput', TagsInputInput);
    app.component('TagsInputItem', TagsInputItem);
    app.component('TagsInputItemDelete', TagsInputItemDelete);
    app.component('TagsInputItemText', TagsInputItemText);

    app.component('NumberField', NumberField);
    app.component('NumberFieldContent', NumberFieldContent);
    app.component('NumberFieldDecrement', NumberFieldDecrement);
    app.component('NumberFieldIncrement', NumberFieldIncrement);
    app.component('NumberFieldInput', NumberFieldInput);

    app.component('Stepper', Stepper);
    app.component('StepperDescription', StepperDescription);
    app.component('StepperItem', StepperItem);
    app.component('StepperSeparator', StepperSeparator);
    app.component('StepperTitle', StepperTitle);
    app.component('StepperTrigger', StepperTrigger);

    app.component('AnimatedList', AnimatedList);
    app.component('Paging', Paging);

    app.component('AIButton', AIButton);
    app.component('ProcessingButton', ProcessingButton);

    app.component('Dropdown', Dropdown);
    app.component('MultiDropdown', MultiDropdown);

    app.component('ConfirmClose', ConfirmClose);
    app.component('ConfirmDelete', ConfirmDelete);
    app.component('Confirm', Confirm);

    app.component('Indicator', Indicator);
    app.component('Pulse', Pulse);

    app.component('CheckCard', CheckCard);
    app.component('MarkdownRenderer', MarkdownRenderer);
    app.component('Modal', Modal);

    app.component('Number', Number);
    app.component('DatePicker', DatePicker);
    
    app.component('ComponentButton', ComponentButton);
    app.component('ComponentAI', ComponentAI);
    app.component('ComponentElement', ComponentElement);
    app.component('ComponentInput', ComponentInput);
    
    app.component('ElementForm', ElementForm);
    app.component('ElementValue', ElementValue);
    
    app.component('ValueDisplay', ValueDisplay);
    app.component('ValueInput', ValueInput);
    
    app.component('CommentDisplay', CommentDisplay);
    app.component('CommentInput', CommentInput);
    
    app.component('Search', Search);
    
    app.component('VoiceRecorder', VoiceRecorder);
    app.component('CameraRecorder', CameraRecorder);
    app.component('ScreenRecorder', ScreenRecorder);
    
    app.component('Crumpets', Crumpets);
    app.component('TimeAgo', TimeAgo);
    app.component('AlinorLogo', AlinorLogo);
    app.component('SubscriptionSwitcher', SubscriptionSwitcher);
}