<script setup>

import { ref, watch, computed, onUnmounted } from 'vue';

import { icons } from '@/utils/icons';

const props = defineProps({
    isDisabled: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'outline'
    },
    undoIcon: {
        type: String,
    },
    deleteIcon: {
        type: String,
    },
    isOpen: {
        type: Boolean,
        default: false
    }
});

const confirmClose = ref(props.isOpen);

const emits = defineEmits(['close']);

onUnmounted(() => {
    confirmClose.value = false;
});

watch(() => props.isOpen, (value) => {
    confirmClose.value = value;
});

const delIcon = computed(() => {
    return props.deleteIcon || icons.xxx;
});

const unIcon = computed(() => {
    return props.undoIcon || icons.xxx;
});

</script>

<template>
    <div>
        <Transition mode="out-in">
            <Button :disabled="props.isDisabled" :variant="props.variant" v-if="!confirmClose" @click="confirmClose = true" size="sm" class="border-dashed shrink-0">
                <Icon :icon="delIcon" class="text-muted-foreground shrink-0" />
            </Button>
            <div v-else class="flex flex-row gap-1 items-center">
                <Button :disabled="props.isDisabled" :variant="props.variant" size="sm" @click="emits('close')" class="shrink-0" >
                    <Icon :icon="delIcon" class="text-destructive shrink-0" />
                </Button>
                <Button :disabled="props.isDisabled" :variant="props.variant" @click="confirmClose = false" size="sm" class="shrink-0" >
                    <Icon :icon="unIcon" class="shrink-0" />
                </Button>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>