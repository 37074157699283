<script setup>

import { computed, ref, watch } from 'vue'
import { createReusableTemplate, useMediaQuery } from '@vueuse/core'
import { VisuallyHidden } from 'radix-vue';

import { useI18n } from 'vue-i18n'

import { Icon } from '@iconify/vue'

import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => []
    },
    title: {
        type: String,
        required: false
    },
    icon: {
        type: String,
        required: false,
        default: icons.plus
    },
    selectedIcon: {
        type: String,
        required: false,
        default: ''
    },
    filterText: {
        type: String,
        default: ''
    },
    modelValue: {
        default: undefined
    },
    forget: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    isOpen: {
        type: Boolean,
        default: false
    },
    enableUndo: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'outline'
    }
})

const itemsToShow = computed(() => props.items.filter(item => item.value !== props.modelValue));

const emits = defineEmits(['update:modelValue', 'update:isOpen']);

const [ListTemplate, List] = createReusableTemplate();
const [ButtonTemplate, TriggerButton] = createReusableTemplate();

const isDesktop = useMediaQuery('(min-width: 768px)');

const isOpen = ref(props.isOpen);
const selectedItem = ref(null)

const value = computed({
    get: () => {
        if (selectedItem.value) {
            return selectedItem.value.label;
        }

        if (props.modelValue !== null && props.modelValue !== undefined) {
            const item = props.items.find(i => i.value === props.modelValue)
            if (item) {
                selectedItem.value = item;
                return selectedItem.value.label;
            }
        }

        return undefined;
    },
    set: val => val,
})

watch(() => props.modelValue, (newVal) => {
    if (newVal !== null && newVal !== undefined) {
        const item = props.items.find(i => i.value === newVal)
        if (item) {
            selectedItem.value = item;
        }
    } else {
        selectedItem.value = null;
    }
})

watch(() => props.isOpen, (newVal) => {
    isOpen.value = newVal;
})

watch(() => isOpen.value, (newVal) => {
    emits('update:isOpen', newVal);
})

function updateValue(value) {
    if (selectedItem.value?.value === value?.value) {
        selectedItem.value = null;
    } else {
        selectedItem.value = value
    }

    isOpen.value = false
    emits('update:modelValue', value.value);

    if (props.forget) {
        selectedItem.value = null;
    }
}

function reset() {
    selectedItem.value = null;
    isOpen.value = false;
    emits('update:modelValue', null);
}



</script>

<template>
    <div class="shrink-0">
        <ButtonTemplate>
            <div class="flex flex-row items-center gap-1">
                <Button :variant="props.variant" class="min-w-fit justify-start" :disabled="props.readonly ? true : false">
                    <Transition mode="out-in">
                        <span v-if="value" class="flex flex-row items-center justify-between w-full gap-3">
                            <span>
                                {{ value }}
                            </span>
                            <Icon v-if="props.selectedIcon" :icon="props.selectedIcon" class="shrink-0" />
                        </span>
                        <span v-else class="flex flex-row items-center justify-between w-full gap-3">
                            <span v-if="props.title">
                                {{ props.title }}
                            </span>
                            <Icon v-if="props.icon" :icon="props.icon" class="shrink-0" />
                        </span>
                    </Transition>
                </Button>
                <Transition mode="out-in">
                    <Button v-if="enableUndo && value" size="sm" variant="secondary" @click.stop="reset">
                        <Icon :icon="icons.undo" class="shrink-0" />
                    </Button>
                </Transition>
            </div>
        </ButtonTemplate>

        <ListTemplate>
            <Command v-if="!props.readonly">
                <CommandInput :placeholder="props.filterText" />
                <CommandList>
                    <CommandEmpty>
                        <slot name="empty">
                            <div class="flex flex-col items-center gap-2">
                                <span>
                                    {{ t('common.noResultsFound') }}
                                </span>
                                <span v-if="props.items.length > 0">
                                    <Button class="border-dashed w-fit" @click.stop="reset">
                                        {{ t('common.reset') }}
                                    </Button>
                                </span>
                            </div>
                        </slot>
                    </CommandEmpty>
                    <CommandGroup>
                        <CommandItem v-for="(item, index) in itemsToShow" :key="index" :value="item.value?.toString()" @select="updateValue(item)" class="hover:cursor-pointer">
                            {{ item.label }}
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </ListTemplate>

        <Label v-if="props.label">{{ t('common.type') }}</Label>
        <div class="min-w-fit">
            <Popover v-if="isDesktop" v-model:open="isOpen">
                <PopoverTrigger as-child>
                    <TriggerButton />
                </PopoverTrigger>
                <PopoverContent class="p-0 w-auto" align="start">
                    <List />
                </PopoverContent>
            </Popover>

            <Drawer v-else :open="isOpen" @update:open="(newOpenValue) => isOpen = newOpenValue">
                <DrawerTrigger as-child>
                    <TriggerButton />
                </DrawerTrigger>
                <DrawerContent>
                    <VisuallyHidden>
                        <DrawerTitle></DrawerTitle>
                    </VisuallyHidden>
                    <VisuallyHidden>
                        <DrawerDescription></DrawerDescription>
                    </VisuallyHidden>
                    <div class="mt-4 border-t">
                        <List />
                    </div>
                </DrawerContent>
            </Drawer>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
