<script setup>

import { ref, computed, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';

import { form } from '@/utils/http';
import { beep } from '@/utils/sound';

import { icons } from '@/utils/icons';

import { handleFileChange, addRef } from '@/lib/utils';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    text: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    processing: {
        type: Boolean,
        default: false
    },
    enableAi: {
        type: Boolean,
        default: true
    },
    icon: {
        type: String,
        default: ''
    },
    extractionType: {
        type: Number,
        default: 0
    },
    enableVariant: {
        type: String,
        default: 'ghost'
    },
    disableAiVariant: {
        type: String,
        default: 'outline'
    }
});

const isExtracting = ref(false);
const isOpen = ref(false);

const extractions = ref({
    withAi: false,
    values: []
});

const isValid = computed(() => {

    if (extractions.value.values.length === 0) {
        return false;
    }

    return extractions.value.values.every(item => {
        if (item.source === 0) {
            return item.file !== null;
        } else if (item.source === 1) {
            return item.url.length > 0;
        } else {
            return item.content.length > 0;
        }
    });

});

const emits = defineEmits(['click', 'update:modelValue', 'extracting']);

const icon = computed(() => {
    return extractions.value.withAi ? icons.agent : props.icon;
});

const buttonVariant = computed(() => {
    if (props.enableAi) {
        return extractions.value.withAi ? 'ai' : props.enableVariant;
    } else {
        return props.disableAiVariant;
    }
});

const cardClass = computed(() => {
    return extractions.value.withAi ? 'bg-zinc-900' : 'bg-default';
});

const switchThumbClass = computed(() => {
    return extractions.value.withAi ? 'bg-zinc-900' : 'bg-background';
});

const toolTipClass = computed(() => {
    return extractions.value.withAi ? 'bg-background text-default ' : 'bg-zinc-900 text-white';
});

const isProcessing = computed(() => {
    return props.processing || isExtracting.value;
});

const isDisabled = computed(() => {
    return props.disabled || isExtracting.value;
});

const extract = async () => {
    isExtracting.value = true;

    emits('extracting', true);
    isOpen.value = false;

    await Promise.all(extractions.value.values.map(item => (form(`ai/extract`, item))))
        .then((values) => {

            values.forEach(item => {
                emits('update:modelValue', item.data);
                beep();
            });

        }).finally(() => {
            isExtracting.value = false;
            emits('extracting', false);
        });
};

const add = () => {
    extractions.value.values.push({
        type: props.extractionType,
        source: 0,
        instructions: '',
        content: '',
        url: '',
        file: null,
    });
};


watch(() => isOpen.value, async (value) => {
    if (!value && !isExtracting.value) {
        extractions.value.values = [];
    } else if (value && extractions.value.values.length === 0) {
        add();
        await nextTick();

        addRef('f-input-0');
    }
});

const updateOpen = (value) => {
    isOpen.value = value;
};

const updateWithAi = (value) => {
    extractions.value.withAi = value;
    updateOpen(value);
};

defineExpose({
    extract,
});

</script>

<template>

    <Card v-if="enableAi" class="flex flex-row p-1 items-center gap-1 pe-1" :class="cardClass">
        <ProcessingButton @click="emits('click', extractions)" :text="props.text" :processing="isProcessing" :disabled="isDisabled" :icon="icon" :variant="buttonVariant" class="border-none" />

        <div class="flex items-center space-x-2">
            <Tooltip>
                <TooltipTrigger class="flex flex-row items-center">
                    <Switch :checked="extractions.withAi" @update:checked="updateWithAi" :disabled="isDisabled" id="with-ai" :class="`data-[state=checked]:bg-input`" :thumpClass="switchThumbClass" />
                </TooltipTrigger>
                <TooltipContent :class="toolTipClass">
                    {{ extractions.withAi ? t('common.WithoutAi') : t('common.withAi') }}
                </TooltipContent>
            </Tooltip>
            <div v-if="extractions.withAi">
                <Popover :open="isOpen" @update:open="updateOpen">
                    <PopoverTrigger as-child>
                        <Button class="border-dashed shrink-0" size="sm" :variant="buttonVariant" :disabled="isDisabled">
                            <Icon :icon="icons.settings" class="shrink-0" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent class="min-w-[320px] w-auto">
                        <div class="grid gap-4">
                            <div class="flex flex-row justify-between">
                                <div class="space-y-2">
                                    <h4 class="font-medium leading-none">
                                        {{ t('common.extractInformation') }}
                                    </h4>
                                    <p class="text-sm text-muted-foreground">
                                        {{ t('common.extractInformationDescription') }}
                                    </p>
                                </div>
                                <div class="flex flex-row justify-between items-center gap-1">
                                    <Button @click="add()" class="border-dashed shrink-0" size="sm" variant="outline" :disabled="isDisabled">
                                        <Icon :icon="icons.plus" class="shrink-0" />
                                    </Button>
                                </div>
                            </div>
                            <div class="flex flex-col gap-2 space-y-2">
                                <AnimatedList :items="extractions.values" :hasRemove="false">
                                    <template #item="{ item, index }">
                                        <div class="flex flex-col w-full gap-2">
                                            <div class="flex flex-row items-center justify-between gap-2 w-full">
                                                <div class="flex flex-row items-start gap-2">
                                                    <Dropdown :modelValue="item.source ?? 0" :items="store.enums.ReferenceType" :title="t('common.source')" :filterText="t('common.filterX', { x: t('common.source') })" @update:modelValue="v => item.source = v" :selectedIcon="icons.extract" :icon="icons.extract" />
                                                </div>

                                                <Button @click="extractions.values.splice(index, 1)" class="shrink-0" size="sm" variant="outline" :disabled="isDisabled">
                                                    <Icon :icon="icons.xxx" class="h-4 w-4 text-destructive shrink-0" />
                                                </Button>
                                            </div>
                                            <div>
                                                <Transition mode="out-in">
                                                    <Input v-if="item.source === 0" :id="`f-input-${index}`" type="file" :placeholder="t('common.document')" @change="e => handleFileChange(e, item)" @blur="e => handleFileChange(e, item)" @rest="e => handleFileChange(e, item)" />
                                                    <Input v-else-if="item.source === 1" :placeholder="t('common.link')" type="text" v-model="item.url" />
                                                    <Textarea v-else-if="item.source === 2" :placeholder="t('common.content')" v-model="item.content" class="mt-2"></Textarea>
                                                </Transition>
                                            </div>
                                        </div>
                                    </template>
                                </AnimatedList>
                                <Transition mode="out-in">
                                    <Button v-if="extractions.values.length > 0" @click="extract" class="w-full" variant="ai" :disabled="!isValid || isProcessing">
                                        {{ t('common.extract') }}
                                        <Icon :icon="icons.ai" class="ml-2 h-4 w-4 shrink-0" />
                                    </Button>
                                </Transition>
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
            <Button v-else @click="updateWithAi(true)" class="hover:cursor-pointer" for="with-ai" size="sm" :variant="buttonVariant" :disabled="isDisabled">
                <Icon :icon="icons.ai" class="shrink-0" />
            </Button>
        </div>

    </Card>
    <ProcessingButton v-else @click="emits('click', extractions)" :text="props.text" :processing="isProcessing" :disabled="isDisabled" :icon="icon" :variant="buttonVariant" />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>