<script setup>

import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { useRouter } from 'vue-router';
import { getDecodedToken } from '@/utils/auth';

import { post } from '@/utils/http';

import { getCommentStatusColor } from '@/lib/utils';

const { t } = useI18n();
const store = useGlobalState();
const router = useRouter();

const props = defineProps({
    comment: {
        type: Object,
        required: true,
    },
    canEdit: {
        type: Boolean,
        default: false,
    },
    displaySettings: {
        type: Object,
        default: () => ({
            showProject: true,
            showPackage: true,
            showVendor: true,
            showComponent: true,
        }),
    }
});

const currentUserId = computed(() => getDecodedToken().id);

const getDeviationColor = () => {
    if (props.comment.isDeviation) {
        return 'bg-red-100 text-red-800';
    }
    return '';
}

const goPro = () => {
    router.push({ name: 'buyer-manage-project', params: { id: props.comment.project.id } });
}

const goPack = () => {
    router.push({ name: 'buyer-manage-package', params: { id: props.comment.package.id } });
}

const isResolveModalOpen = ref(false);
const isResolving = ref(false);

const resolveItem = ref({
    id: props.comment.id,
    text: '',
});

watch(() => isResolveModalOpen.value, (v) => {
    if (!v) {
        resolveItem.value.text = '';
    }

    isResolving.value = false;
});

const resolve = () => {
    if (!props.canEdit) {
        return;
    }

    if (isResolving.value) {
        return;
    }

    if (!resolveItem.value.text) {
        return;
    }

    isResolving.value = true;

    post(`/procurement/work-items/resolve`, {
        id: resolveItem.value.id,
        text: resolveItem.value.text
    }).then(({ data }) => {

        props.comment.status = data.status;

        isResolveModalOpen.value = false;
    }).finally(() => {
        isResolving.value = false;
    });
}

const isAssignModalOpen = ref(false);
const isAssigning = ref(false);

const assignItem = ref({
    id: props.comment.id,
    userId: null,
    targetDate: null,
});

watch(() => isAssignModalOpen.value, (v) => {
    if (!v) {
        assignItem.value.userId = null;
        assignItem.value.targetDate = null;
    }

    isAssigning.value = false;
});

const assign = () => {
    if (!props.canEdit) {
        return;
    }

    if (isAssigning.value) {
        return;
    }

    if (!assignItem.value.userId || !assignItem.value.targetDate) {
        return;
    }

    isAssigning.value = true;

    post(`/procurement/work-items/assign`, {
        id: assignItem.value.id,
        userId: assignItem.value.userId,
        targetDate: assignItem.value.targetDate
    }).then(({ data }) => {

        props.comment.assignedTo = data.assignedTo;
        props.comment.targetDate = data.targetDate;

        isAssignModalOpen.value = false;
    }).finally(() => {
        isAssigning.value = false;
    });
}

const isReplyModalOpen = ref(false);
const isReplying = ref(false);
const replyItem = ref({
    id: props.comment.id,
    resolve: false,
    text: '',
});

watch(() => isReplyModalOpen.value, (v) => {
    if (!v) {
        replyItem.value.text = '';
        replyItem.value.resolve = false;
    }
});

const reply = () => {
    if (!props.canEdit) {
        return;
    }

    if (!replyItem.value.text) {
        return;
    }

    post(`/procurement/comments/reply`, {
        id: replyItem.value.id,
        text: replyItem.value.text,
        resolve: replyItem.value.resolve,
    }).then(({ data }) => {
        isReplyModalOpen.value = false;
    });
}

const rowClass = computed(() => props.comment.createdBy.id === parseInt(currentUserId.value) ? 'flex-row-reverse' : 'flex-row');

</script>

<template>
    <div class="flex items-start gap-1 w-full" :class="rowClass">
        <Transition mode="out-in">
            <div v-if="comment.createdBy">
                <Tooltip>
                    <TooltipTrigger>
                        <Avatar>
                            <AvatarFallback>{{ comment.createdBy.initials }}</AvatarFallback>
                        </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>
                        {{ comment.createdBy.name }}
                    </TooltipContent>
                </Tooltip>
            </div>
        </Transition>
        <div class="flex flex-col w-full">
            <Card class="flex items-center justify-between gap-1 bg-muted rounded-t-sm rounded-b-none p-2" :class="rowClass">
                <div class="flex gap-1 items-center" :class="rowClass">
                    <span class="text-sm font-semibold">
                        {{ comment.createdBy.name }}
                    </span>
                    <Transition mode="out-in">
                        <TimeAgo variant="outline" class="bg-white" v-if="comment.utcCreated" :date="comment.utcCreated" :indicateColor="false" :label="t('common.sent')" />
                    </Transition>
                    <Transition mode="out-in">
                        <Badge v-if="comment.assignedTo" class="flex flex-row gap-2">
                            <Icon :icon="icons.user" class="shrink-0" />
                            <span>{{ t('common.assignedTo') }}</span>
                            <span>{{ comment.assignedTo.name }}</span>
                        </Badge>
                    </Transition>
                </div>
                <div class="flex flex-row gap-1 items-center">
                    <Transition mode="out-in">
                        <div v-if="(comment.project || comment.package || comment.vendor || comment.packageComponent) && (props.displaySettings?.showProject || props.displaySettings?.showPackage || props.displaySettings?.showVendor || props.displaySettings?.showComponent)" class="flex flex-row gap-1">
                            <template v-if="comment.project && props.displaySettings?.showProject">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Badge class="flex flex-row gap-1 hover:cursor-pointer" @click="goPro">
                                            <Icon :icon="icons.project" class="shrink-0" />
                                            <span>{{ comment.project.name }}</span>
                                        </Badge>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {{ t('common.project') }}
                                    </TooltipContent>
                                </Tooltip>
                            </template>
                            <template v-if="comment.package && props.displaySettings?.showPackage">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Badge class="flex flex-row gap-1 hover:cursor-pointer bg-white" variant="outline" @click="goPack">
                                            <Icon :icon="icons.package" class="shrink-0" />
                                            <span>{{ comment.package.name }}</span>
                                        </Badge>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {{ t('common.package') }}
                                    </TooltipContent>
                                </Tooltip>
                            </template>
                            <template v-if="comment.packageComponent && props.displaySettings?.showComponent">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Badge class="flex flex-row gap-1 bg-white" variant="outline">
                                            <Icon :icon="icons.input" class="shrink-0" />
                                            <span>{{ comment.packageComponent.name }}</span>
                                        </Badge>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {{ t('common.requirement') }}
                                    </TooltipContent>
                                </Tooltip>
                            </template>
                            <template v-if="comment.vendor && props.displaySettings?.showVendor">
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Badge v-if="comment.vendor" class="flex flex-row gap-1 bg-white" variant="outline">
                                            <Icon :icon="icons.vendors" class="shrink-0" />
                                            <span>{{ comment.vendor.name }}</span>
                                        </Badge>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {{ t('common.vendor') }}
                                    </TooltipContent>
                                </Tooltip>
                            </template>
                        </div>
                    </Transition>
                    <Transition mode="out-in">
                        <div v-if="comment.isDeviation">
                            <Tooltip>
                                <TooltipTrigger>
                                    <Icon :icon="icons.deviation" class="shrink-0" />
                                </TooltipTrigger>
                                <TooltipContent>
                                    {{ t('common.deviation') }}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </Transition>
                </div>
            </Card>
            <Card class="flex flex-row items-center justify-between gap-1 rounded-t-none rounded-b-sm p-2" :class="getDeviationColor()">
                <div class="flex flex-col gap-1">
                    <Transition mode="out-in">
                        <span v-if="comment.title">{{ comment.title }}</span>
                    </Transition>
                    <span>
                        {{ comment.text }}
                    </span>
                    <Transition mode="out-in">
                        <TimeAgo v-if="comment.targetDate" :date="comment.targetDate" :label="t('common.targetDate')" />
                    </Transition>
                </div>
                <div class="flex flex-row items-center gap-x-3">
                    <Transition mode="out-in">
                        <div v-if="comment.status !== null && comment.status !== undefined" class="flex flex-col items-center gap-2">
                            <Badge variant="outline" class="w-fit" :class="getCommentStatusColor(comment.status)">
                                {{ store.ev(store.enums.CommentStatus, comment.status) }}
                            </Badge>
                        </div>
                    </Transition>
                    <DropdownMenu v-if="props.canEdit">
                        <DropdownMenuTrigger as-child>
                            <Button aria-haspopup="true" size="sm" variant="outline" class="text-black">
                                <Icon :icon="icons.more" class="shrink-0" />
                                <span class="sr-only">Toggle menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>{{ t('common.actions') }}</DropdownMenuLabel>
                            <DropdownMenuItem @click="isReplyModalOpen = true" class="flex flex-row justify-between items-center hover:cursor-pointer">
                                <span>{{ t('common.reply') }}</span>
                                <Icon :icon="icons.reply" class="shrink-0" />
                            </DropdownMenuItem>
                            <DropdownMenuItem @click="isResolveModalOpen = true" class="flex flex-row justify-between items-center hover:cursor-pointer">
                                <span>{{ t('common.resolve') }}</span>
                                <Icon :icon="icons.check" class="shrink-0" />
                            </DropdownMenuItem>
                            <DropdownMenuItem @click="isAssignModalOpen = true" class="flex flex-row justify-between items-center hover:cursor-pointer">
                                <span>{{ t('common.assignTo') }}</span>
                                <Icon :icon="icons.user" class="shrink-0" />
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </Card>
            <Transition mode="out-in">
                <Card v-if="isReplyModalOpen" class="flex flex-col gap-2 p-4 border border-dashed rounded-md mt-2">
                    <Textarea v-model="replyItem.text" class="w-full h-full" :placeholder="t('common.reply')"></Textarea>
                    <div class="flex flex-row items-center gap-1">
                        <Switch id="resolve-mode" :checked="replyItem.resolve" @update:checked="() => replyItem.resolve = !replyItem.resolve" />
                        <Label for="resolve-mode" class="text-sm text-muted-foreground hover:cursor-pointer flex flex-row items-center gap-1">
                            {{ t('common.resolve') }}
                            <Icon :icon="replyItem.resolve" class="shrink-0" />
                        </Label>
                    </div>
                    <div class="flex flex-row w-full justify-between">
                        <Button @click="isReplyModalOpen = false" variant="secondary">{{ t('common.cancel') }}</Button>
                        <ProcessingButton :disabled="!replyItem.text" @click="reply" :processing="isReplying" variant="default" :text="t('common.reply')" />
                    </div>
                </Card>
            </Transition>            
            <Transition mode="out-in">
                <Card v-if="isResolveModalOpen" class="flex flex-col gap-2 p-4 border border-dashed rounded-md mt-2">
                    <Textarea v-model="resolveItem.text" class="w-full h-full" :placeholder="t('common.reason')"></Textarea>
                    <div class="flex flex-row w-full justify-between">
                        <Button @click="isResolveModalOpen = false" variant="secondary">{{ t('common.cancel') }}</Button>
                        <ProcessingButton :disabled="!resolveItem.text" @click="resolve" :processing="isResolving" variant="default" :text="t('common.resolve')" />
                    </div>
                </Card>
            </Transition>
            <Transition mode="out-in">
                <Card v-if="isAssignModalOpen" class="flex flex-col gap-2 p-4 border border-dashed rounded-md mt-2">
                    <div class="flex flex-row gap-2">
                        <Dropdown :items="store.types.users" :title="t('common.assignToUser')" :icon="icons.user" :modelValue="assignItem.userId" @update:modelValue="v => assignItem.userId = v" />
                        <DatePicker :modelValue="assignItem.targetDate" :title="t('common.targetDate')" @update:modelValue="v => assignItem.targetDate = v" />
                    </div>
                    <div class="flex flex-row w-full justify-between">
                        <Button @click="isAssignModalOpen = false" variant="secondary">{{ t('common.cancel') }}</Button>
                        <ProcessingButton :disabled="!assignItem.userId || !assignItem.targetDate" @click="assign" :processing="isAssigning" variant="default" :text="t('common.assignToUserTitle')" />
                    </div>
                </Card>
            </Transition>
        </div>

    </div>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>