<script setup>

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { v4 as uuidv4 } from 'uuid';

import { onBeforeEnter, onEnter, onLeave } from '@/utils/animations';

import { icons } from '@/utils/icons';
import UserForm from '../Forms/UserForm.vue';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    componenet: Object,
});

const emits = defineEmits(['update:edit', 'update:save']);

const copy = ref(null);
const edit = (c, scheme) => {
    copy.value = JSON.parse(JSON.stringify(c));
    copy.value.scheme = scheme;

    if (props.componenet) {
        props.componenet.isSettingsOpen = false;
        props.componenet.isSettings2Open = false;

        props.componenet.hasAssignedUser = false;
    }
};

const saveCopy = () => {
    emits('update:save', { ...copy.value });

    copy.value = null;

    if (props.componenet) {
        props.componenet.isSettingsOpen = false;
        props.componenet.isSettings2Open = false;


        props.componenet.hasAssignedUser = false;
    }
};

const removeElement = (index) => {
    props.componenet.elements.splice(index, 1);
};

const duplicateElement = (index) => {
    const copy = JSON.parse(JSON.stringify(props.componenet.elements[index]));

    copy.isSettingsOpen = false;
    props.componenet.elements[index].isSettingsOpen = false;

    copy.uid = uuidv4();
    props.componenet.elements.splice(index, 0, copy);
};


const isUserModalOpen = ref(false);
const userItem = ref(null);

const createUser = (c, e) => {
    userItem.value = {
        c: c,
        e: e,
    };
    isUserModalOpen.value = true;
}

const closeUserModal = () => {
    isUserModalOpen.value = false;
    userItem.value = {};
}

const addNewUser = (v) => {
    if (!store.lookups.users.find(c => c.id === v.id)) {
        store.lookups.users.push(v);
    }

    if (userItem.value.c && userItem.value.e) {
        userItem.value.e.ownerId = v.id;
    } else {
        props.componenet.ownerId = v.id;
    }

    closeUserModal();
}

</script>

<template>
    <Transition mode="out-in">

        <Card v-if="copy" class="flex flex-col p-3">
            <ComponentInput :item="copy" :scheme="copy.scheme" :enableInput="false" :enableCopy="false" :enableRemove="false" @update:edit="v => { emits('update:edit', v); !v ? copy = null : true; }" @update:save="saveCopy">
                <template #controls>
                    <div class="flex flex-row items-center justify-between mt-4 w-full">

                        <Button variant="secondary" @click="copy = null">
                            {{ t('common.cancel') }}
                        </Button>

                        <Button variant="default" @click="saveCopy">
                            {{ t('common.save') }}
                        </Button>

                    </div>
                </template>
            </ComponentInput>
        </Card>
        <div v-else class="flex flex-col space-y-2 justify-top w-full">

            <Transition mode="out-in">
                <Card v-if="props.componenet" class="p-3 flex flex-col gap-2">
                    <div class="flex flex-row items-center justify-between gap-1">

                        <div class="space-y-1 leading-none flex flex-row w-full justify-between items-center">
                            <div class="text-muted-foreground text-xs">{{ t('common.requirementBuyerInputDescription') }}</div>
                        </div>


                        <div class="flex flex-row items-center">

                            <Transition mode="out-in">

                                <div v-if="props.componenet.elements.filter(x => x.scheme === 0).length > 0" class="flex flex-row items-center gap-2">
                                    <Tooltip>
                                        <TooltipTrigger as-child>
                                            <Button :variant="props.componenet.hasAssignedUser ? 'default' : 'ghost'" size="sm" @click="() => { props.componenet.hasAssignedUser = !props.componenet.hasAssignedUser; !props.componenet.hasAssignedUser ? props.componenet.ownerId = null : props.componenet.ownerId = props.componenet.ownerId }">
                                                <Icon :icon="icons.user" />
                                                <span class="sr-only">
                                                    {{ t('common.assignToUser') }}
                                                </span>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {{ t('common.assignToUser') }}
                                        </TooltipContent>
                                    </Tooltip>
                                    <div class="flex flex-row items-center gap-1">
                                        <Transition mode="out-in">
                                            <Dropdown v-if="props.componenet.hasAssignedUser || !!props.componenet.ownerId" :items="store.types.users" :title="t('common.person')" :icon="icons.user" :modelValue="props.componenet.ownerId" @update:modelValue="v => props.componenet.ownerId = v">
                                                <template #empty>
                                                    <div class="flex flex-col items-center gap-2">
                                                        <span>
                                                            {{ t('common.noResultsFound') }}
                                                        </span>
                                                        <Button class="border-dashed w-fit" @click="createUser(props.componenet, null)">
                                                            {{ t('common.create') }} {{ t('common.new') }}
                                                        </Button>
                                                    </div>
                                                </template>
                                            </Dropdown>
                                        </Transition>
                                        <Transition mode="out-in">
                                            <Button v-if="props.componenet.ownerId" @click="props.componenet.ownerId = null" variant="secondary" size="sm" class="shrink-0">
                                                <Icon :icon="icons.xxx" class="shrink-0" />
                                            </Button>
                                        </Transition>
                                    </div>
                                </div>

                            </Transition>

                            <Tooltip>
                                <TooltipTrigger>
                                    <Button size="sm" class="shrink-0" variant="ghost" @click="edit(props.componenet, 0)">
                                        <Icon :icon="icons.edit" class="shrink-0" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {{ t('common.edit') }}
                                </TooltipContent>
                            </Tooltip>
                        </div>

                    </div>

                    <Transition mode="out-in">

                        <div v-if="props.componenet.elements.filter(x => x.scheme === 0).length > 0" class="w-full space-y-3">
                            <TransitionGroup name="list" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">

                                <div v-for="(e, eIndex) in props.componenet.elements.filter(x => x.scheme === 0)" :key="eIndex" class="flex flex-col">
                                    <div class="flex flex-col w-full gap-1.5">
                                        <ElementValue :element="e">

                                            <template #label>
                                                <Tooltip>
                                                    <TooltipTrigger>
                                                        <Popover :open="e.isSettingsOpen" @update:open="o => e.isSettingsOpen = o">
                                                            <PopoverTrigger as-child>
                                                                <Icon :icon="icons.settings" class="shrink-0 text-muted-foreground text-xs" />
                                                            </PopoverTrigger>
                                                            <PopoverContent class="min-w-64 w-auto">
                                                                <div class="grid gap-4">
                                                                    <div class="flex flex-row justify-between items-center">
                                                                        <h4 class="font-medium leading-none">
                                                                            {{ t('common.settings') }}
                                                                        </h4>
                                                                        <Button variant="outline" size="sm" @click="e.isSettingsOpen = !e.isSettingsOpen">
                                                                            <Icon :icon="icons.check" class="shrink-0" />
                                                                        </Button>
                                                                    </div>
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="width">{{ t('common.name') }}</Label>
                                                                        <Input v-model="e.name" class="col-span-2 h-8" />
                                                                    </div>
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="width">{{ t('common.assignToUser') }}</Label>
                                                                        <div class="flex flex-row items-center gap-1">
                                                                            <Dropdown :items="store.types.users" :title="t('common.person')" :icon="icons.user" :modelValue="e.ownerId" @update:modelValue="v => e.ownerId = v">
                                                                                <template #empty>
                                                                                    <div class="flex flex-col items-center gap-2">
                                                                                        <span>
                                                                                            {{ t('common.noResultsFound') }}
                                                                                        </span>
                                                                                        <Button class="border-dashed w-fit" @click="createUser(props.componenet, e)">
                                                                                            {{ t('common.create') }} {{ t('common.new') }}
                                                                                        </Button>
                                                                                    </div>
                                                                                </template>
                                                                            </Dropdown>
                                                                            <Transition mode="out-in">
                                                                                <Button v-if="e.ownerId" @click="e.ownerId = null" variant="secondary" size="sm" class="shrink-0">
                                                                                    <Icon :icon="icons.xxx" class="shrink-0" />
                                                                                </Button>
                                                                            </Transition>

                                                                        </div>
                                                                    </div>
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="width">{{ t('common.actions') }}</Label>
                                                                        <div class="flex flex-row items-center gap-1">
                                                                            <Tooltip>
                                                                                <TooltipTrigger>
                                                                                    <Button size="sm" class="shrink-0" variant="outline" @click="duplicateElement(eIndex)">
                                                                                        <Icon :icon="icons.copy" class="shrink-0" />
                                                                                    </Button>
                                                                                </TooltipTrigger>
                                                                                <TooltipContent>
                                                                                    {{ t('common.duplicate') }}
                                                                                </TooltipContent>
                                                                            </Tooltip>
                                                                            <ConfirmClose :deleteIcon="icons.trash" @close="removeElement(eIndex)" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        {{ t('common.settings') }}
                                                    </TooltipContent>
                                                </Tooltip>
                                            </template>
                                            
                                        </ElementValue>

                                        <Transition mode="out-in">
                                            <Badge v-if="e.ownerId" class="text-muted-foreground text-xs flex flex-row items-center gap-1 w-fit" variant="secondary">
                                                <span>
                                                    {{ t('common.assignedTo') }}
                                                </span>
                                                <span>
                                                    {{ store.tv('users', e.ownerId) }}
                                                </span>
                                                <Icon :icon="icons.xxx" class="hover:cursor-pointer text-destructive shrink-0" @click="e.ownerId = null" />
                                            </Badge>
                                        </Transition>

                                    </div>
                                </div>

                            </TransitionGroup>
                        </div>

                    </Transition>

                </Card>
            </Transition>


            <Transition mode="out-in">
                <Card v-if="props.componenet" class="flex flex-row items-center gap-x-3 space-y-0 rounded-md border-dashed p-4 grow shrink-0 hover:cursor-pointer" @click="edit(props.componenet, 1)">

                    <div class="space-y-1 leading-none flex flex-row w-full justify-between items-center">
                        <div class="flex flex-col gap-2 grow">
                            <div class="text-muted-foreground text-xs">{{ t('common.requirementVendorInputDescription') }}</div>
                            <Transition mode="out-in">
                                <div v-if="props.componenet.elements.filter(x => x.scheme === 1).length > 0" class="flex flex-row items-center gap-1">
                                    <Badge v-for="(e, index) in props.componenet.elements.filter(x => x.scheme === 1)" :key="index" variant="secondary" class="w-fit">
                                        {{ e.name }}
                                    </Badge>
                                </div>
                            </Transition>
                        </div>
                        <Tooltip>
                            <TooltipTrigger>
                                <Button size="sm" class="shrink-0" variant="ghost">
                                    <Icon :icon="icons.edit" class="shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {{ t('common.edit') }}
                            </TooltipContent>
                        </Tooltip>
                    </div>

                </Card>
            </Transition>

        </div>

    </Transition>


    <Modal :open="isUserModalOpen" @update:open="v => isUserModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div class="flex flex-col grow">
                    <UserForm :item="userItem" @close="closeUserModal" @update:modelValue="v => addNewUser(v.new)" />
                </div>
            </div>
        </template>

    </Modal>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>