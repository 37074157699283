<script setup>

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';

import { icons } from '@/utils/icons';

import { handleFileChange, addRef } from '@/lib/utils';

import { form } from '@/utils/http';

const { t } = useI18n();

const props = defineProps({
    isPosting: Boolean,
    useAI: Boolean,
});

const emits = defineEmits(['update:components', 'update:useAI', 'update:isPosting']);

const aiBuild = ref({
    id: uuidv4(),
    file: null,
    instructions: null,
    isGenerating: false,
    useAI: props.useAI,
});

const gen = async () => {
    if (!aiBuild.value.instructions) {
        return;
    }

    if (aiBuild.value.isGenerating) {
        return;
    }

    aiBuild.value.isGenerating = true;
    emits('update:isPosting', true);

    const { data } = await form('/global/ai/rfx', {
        payload: aiBuild.value,
    });

    aiBuild.value.isGenerating = false;
    aiBuild.value.instructions = null;

    emits('update:isPosting', false);
    emits('update:components', data);
}

watch(() => aiBuild.value.file, (_) => {
    aiBuild.value.id = uuidv4();
}, { deep: true });

watch(() => aiBuild.value.useAI, (value) => {
    emits('update:useAI', value);
});

watch(() => props.useAI, (value) => {
    aiBuild.value.useAI = value;
});

</script>

<template>
    <div class="flex flex-row items-center justify-between gap-1">
        <div class="flex flex-row items-center gap-1">
            <Switch id="ai-mode" :checked="aiBuild.useAI" @update:checked="() => aiBuild.useAI = !aiBuild.useAI" />
            <Label for="ai-mode" class="text-sm text-muted-foreground hover:cursor-pointer flex flex-row items-center gap-1">
                {{ t('common.useAI') }}
                <Icon :icon="icons.ai" class="shrink-0" />
            </Label>
        </div>
    </div>

    <Transition mode="out-in">
        <div v-if="aiBuild.useAI" class="flex flex-col w-full space-y-2.5">
            <Textarea v-model="aiBuild.instructions" class="w-full h-24" :placeholder="t('common.rfxAiDescription')"></Textarea>

            <Card class="p-4 flex flex-row items-center justify-between" @click.stop="addRef('ref-input')">
                <div class="flex flex-col grow">
                    <h1 class="text-lg font-semibold">
                        {{ t('common.reference') }}
                        <span class="text-sky-500 text-xs">({{ t('common.optional') }})</span>
                    </h1>
                    <div class="text-xs text-muted-foreground">{{ t('common.uploadRefDescription') }}</div>
                </div>

                <Input id="ref-input" class="w-1/2" type="file" @click.stop :placeholder="t('common.reference')" @change="e => handleFileChange(e, aiBuild)" @blur="e => handleFileChange(e, aiBuild)" @rest="e => handleFileChange(e, aiBuild)" />
            </Card>

            <div class="flex flex-row justify-end">
                <ProcessingButton @click="gen" :disabled="isPosting || aiBuild.isGenerating || !aiBuild.instructions" :processing="isPosting || aiBuild.isGenerating" :text="t('common.add')" />
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>