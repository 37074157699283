<script setup>

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { getCurrentSubscription } from '@/utils/auth';

const { t } = useI18n();

const props = defineProps({
    item: Object,
    element: Object
});

const fileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileNameParts = fileName.split('-');
    const fileNameWithoutUuid = fileNameParts.slice(0, fileNameParts.length - 1).join('-');
    return fileNameWithoutUuid;
};

const url = computed(() => getCurrentSubscription().isBuyer ? '/global/ai/document' : '/hi/global/ai/document');

const searchItem = ref({
    url: null,
    isOpen: false
});

const ser = (url) => {
    searchItem.value.url = url;
    searchItem.value.isOpen = true;
};

const op = (isOpen) => {
    if (!isOpen) {
        searchItem.value.url = null;
        searchItem.value.isOpen = false;
    }
};

const requiresReadMore = ref(props.element.type === 1 && props.item.value && props.item.value.length > 200);
const readMoreActivated = ref(props.element.type === 1 && props.item.value && props.item.value.length > 200);

</script>

<template>
    <div class="flex flex-row items-center gap-2">
        <span v-if="!item.value" class="text-muted-foreground">{{ t('common.noValue') }}</span>
        <div v-else-if="element.type === 1">
            <Transition mode="out-in">
                <div v-if="requiresReadMore">
                    <Transition mode="out-in">
                        <div v-if="readMoreActivated" class="flex flex-col gap-1">
                            <p class="leading-7 [&:not(:first-child)]:mt-6">
                                {{ item.value.slice(0, 200) }}...
                            </p>
                            <a @click="readMoreActivated = false" class="hover:cursor-pointer underline text-sky-500">
                                {{ t('common.readMore') }}
                            </a>
                        </div>
                        <div v-else class="flex flex-col gap-1">
                            <p class="leading-7 [&:not(:first-child)]:mt-6">
                                {{ item.value }}
                            </p>
                            <a @click="readMoreActivated = true" class="hover:cursor-pointer underline text-sky-500">
                                {{ t('common.readLess') }}
                            </a>
                        </div>
                    </Transition>
                </div>
                <div v-else>
                    <span>{{ item.value }}</span>
                </div>
            </Transition>
        </div>

        <div v-else-if="element.type === 4" class="flex flex-row gap-1 items-center">
            <Tooltip>
                <TooltipTrigger>
                    <Button variant="outline" class="text-primary" @click="ser(item.value)" size="sm">
                        <Icon :icon="icons.ai" class="shrink-0" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {{ t('common.searchWithAi') }}
                </TooltipContent>
            </Tooltip>

            <Tooltip>
                <TooltipTrigger>
                    <Button as="a" variant="outline" class="text-primary" :href="item.value" size="sm">
                        <Icon :icon="icons.download" class="shrink-0" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {{ t('common.download') }}
                </TooltipContent>
            </Tooltip>
            <span>{{ fileNameFromUrl(item.value) }}</span>
        </div>

        <audio v-else-if="element.type === 6" :src="item.value" controls></audio>

        <video v-else-if="element.type === 7" :src="item.value" controls></video>

        <video v-else-if="element.type === 8" :src="item.value" controls></video>

        <MarkdownRenderer v-else-if="element.type === 1" :content="item.value" class="p-0" />

        <span v-else-if="element.type === 5">
            <Badge v-if="item.value === '1'" class="w-fit" variant="outline">{{ t('common.yes') }}</Badge>
            <Badge v-else class="w-fit" variant="destructive">{{ t('common.no') }}</Badge>
        </span>

        <span v-else>{{ item.value }}</span>
    </div>

    <Search :url="url" :filters="{ url: searchItem.url }" :showMainSearch="false" :isOpen="searchItem.isOpen" @update:open="op" />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>