
export const hasValidLimit = (item) => {
    const validCount = item.inputCount !== undefined && item.inputCount !== null;
    const validLimit = item.inputCount === 1 ? item.responseLimit > 0 : true;

    return validCount && validLimit;
}

export const hasValue = (element) => {
    if (!element) {
        return false;
    }

    if (!element.values || element.values.length === 0) {
        return false;
    }

    return element.values.every(val => {
        const isNotNull = val.value !== null && val.value !== undefined;
        const isNotNaN = val.value !== NaN;
        const isNotEmpty = val.value !== "";
        const isFile = element.type === 4 || element.type === 6 || element.type === 7 || element.type === 8;

        if (element.type === 2 || element.type === 9 || element.type === 13) {

            return isNotNull && isNotNaN && val.value !== "NaN"

        } else if (isFile) {

            const hasFile = val.file !== null && val.file !== undefined;
            return hasFile || (isNotNull && isNotNaN && isNotEmpty);

        } else if(element.type === 5) {

            const isFalseValue = val.value === '0' || val.value === 0  || val.value === null || val.value === undefined;
            const isTrueValue = val.value === '1' || val.value === 1;

            return isTrueValue || isFalseValue;

        } else {
            return isNotNull && isNotNaN && isNotEmpty
        }
    });
}

const isValidElementValue = (element) => {
    if (!element) {
        return true;
    }

    if (!element.values || element.values.length === 0) {
        return false;
    }

    const isFile = element.type === 4 || element.type === 6 || element.type === 7 || element.type === 8;
    const hasCorrectResponseLimit = element.inputCount === 1 ? element.values.length < element.responseLimit : true;
    const hasCorrectValues = isFile ? true : hasValue(element);

    return hasCorrectResponseLimit && hasCorrectValues;
}

export const isValidElementValues = (elements) => {
    if (!elements) {
        return true;
    }

    if (elements.length === 0) {
        return true;
    }

    return elements.every(x => isValidElementValue(x));
}

const isValidElement = (element) => {
    if (!element) {
        return true;
    }

    const validName = !!element.name;
    const validType = element.type !== undefined && element.type !== null;
    const isLimitValid = hasValidLimit(element);

    return validName && validType && isLimitValid;
}

export const isValidElements = (elements) => {
    if (!elements) {
        return true;
    }

    if (elements.length === 0) {
        return true;
    }

    return elements.every(x => isValidElement(x));
}

export const gCCI = (elements) => {
    if (!elements || elements.length === 0) {
        return {
            text: '',
            color: '',
            total: 0,
            completed: 0,
        };
    }

    const own = elements;
    const total = own.length;
    const completed = own.filter(x => hasValue(x)).length;

    return {
        text: `${completed}/${total}`,
        color: completed === total ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800',
        total: total,
        completed: completed,
    };
}