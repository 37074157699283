<script setup>

import { ref, computed, watch } from 'vue'
import { createReusableTemplate, useMediaQuery } from '@vueuse/core'

import { useI18n } from 'vue-i18n'

import { Icon } from '@iconify/vue'

import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => []
    },
    title: {
        type: String,
        required: false
    },
    icon: {
        type: String,
        required: false,
        default: icons.plus
    },
    selectedIcon: {
        type: String,
        required: false,
        default: ''
    },
    filterText: {
        type: String,
        default: ''
    },
    modelValue: {
        type: Array,
        default: undefined
    },
    label: {
        type: String,
        default: ''
    },
    open: {
        type: Boolean,
        default: false
    }
})

const itemsToShow = computed(() => {
    return props.items.filter(item => !props.modelValue.includes(item.value))
})

const emits = defineEmits(['update:modelValue']);

const [UseTemplate, StatusList] = createReusableTemplate();
const isDesktop = useMediaQuery('(min-width: 768px)');

const isOpen = ref(props.open);
const selectedItems = ref([]);

const value = computed({
    get: () => {
        if (selectedItems.value.length > 0) {
            return selectedItems.value.map(x => x.label).join(', ');
        }

        if (props.modelValue !== null && props.modelValue !== undefined) {

            const items = props.items.filter(i => props.modelValue.includes(i.value))
            if (items.length > 0) {
                selectedItems.value = items.map(x => ({ ...x }));
                return selectedItems.value.map(x => x.label).join(', ');
            }
        }

        return undefined;
    },
    set: val => val,
})

watch(() => props.modelValue, (newVal) => {
    if (newVal !== null && newVal !== undefined) {
        const item = props.items.find(i => i.value === newVal)
        const items = props.items.filter(i => newVal.includes(i.value))

        if (items.length > 0) {
            selectedItems.value = items.map(x => ({ ...x }));
        }
    } else {
        selectedItems.value = [];
    }
})

function updateValue(value) {

    if (selectedItems.value.find(x => x.value === value.value)) {
        selectedItems.value = selectedItems.value.filter(x => x.value !== value.value);
    } else {
        selectedItems.value.push(value);
    }

    isOpen.value = false;
    const ids = selectedItems.value.map(x => x.value);
    emits('update:modelValue', ids);
}


</script>

<template>
    <div>
        <Label v-if="props.label">{{ t('common.type') }}</Label>
        <div class="min-w-fit">
            <UseTemplate>
                <Command>
                    <CommandInput :placeholder="props.filterText" />
                    <CommandList>
                        <CommandEmpty>
                            <slot name="empty">
                                <div class="flex flex-col items-center gap-2">
                                    <span>
                                        {{ t('common.noResultsFound') }}
                                    </span>
                                    <span v-if="props.items.length > 0">
                                        <Button class="border-dashed w-fit" @click.stop="reset">
                                            {{ t('common.reset') }}
                                        </Button>
                                    </span>
                                </div>
                            </slot>
                        </CommandEmpty>
                        <CommandGroup>
                            <CommandItem class="hover:cursor-pointer" v-for="(item, index) in itemsToShow" :key="index" :value="item.value" @select="updateValue(item)" :class="item.textColor">
                                <span class="flex flex-row items-center justify-between w-full">
                                    <span>
                                        {{ item.label }}
                                    </span>
                                    <Icon v-if="props.selectedIcon" :icon="props.selectedIcon" class="shrink-0" />
                                </span>
                            </CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </UseTemplate>

            <Popover v-if="isDesktop" v-model:open="isOpen">
                <PopoverTrigger as-child>
                    <Button variant="outline" class="min-w-fit justify-between">
                        <Transition mode="out-in">
                            <span v-if="value" class="flex flex-row items-center justify-between w-full gap-3">
                                <span>
                                    {{ value }}
                                </span>
                                <Icon v-if="props.selectedIcon" :icon="props.selectedIcon" class="shrink-0" />
                            </span>
                            <span v-else class="flex flex-row items-center justify-between w-full">
                                <Icon v-if="props.icon" :icon="props.icon" class="shrink-0" />
                                <span v-if="props.title">
                                    {{ props.title }}
                                </span>
                            </span>
                        </Transition>
                    </Button>
                </PopoverTrigger>
                <PopoverContent class="w-full p-0">
                    <StatusList />
                </PopoverContent>
            </Popover>

            <Drawer v-else :open="isOpen" @update:open="o => isOpen = o">
                <DrawerTrigger as-child>
                    <Button variant="outline" class="min-w-fit justify-between">
                        <Transition mode="out-in">
                            <span v-if="value" class="flex flex-row items-center justify-between w-full gap-3">
                                <span>
                                    {{ value }}
                                </span>
                                <Icon v-if="props.selectedIcon" :icon="props.selectedIcon" class="shrink-0" />
                            </span>
                            <span v-else class="flex flex-row items-center justify-between w-full">
                                <Icon v-if="props.icon" :icon="props.icon" class="shrink-0" />
                                <span v-if="props.title">
                                    {{ props.title }}
                                </span>
                            </span>
                        </Transition>
                    </Button>
                </DrawerTrigger>
                <DrawerContent>
                    <div class="mt-4 border-t">
                        <StatusList />
                    </div>
                </DrawerContent>
            </Drawer>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
