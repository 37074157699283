<script setup>

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';

import UserForm from '@/views/_shared/Components/Forms/UserForm.vue';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    vendors: {
        type: Array,
        required: true
    },
    comment: {
        type: Object,
        required: true
    }
});

const emits = defineEmits(['update:open']);

const isUserModalOpen = ref(false);
const userItem = ref(null);

const createUser = () => {
    userItem.value = {};
    isUserModalOpen.value = true;
}

const closeUserModal = () => {
    isUserModalOpen.value = false;
    userItem.value = {};
}
const addNewUser = (v) => {
    if (!store.lookups.users.find(c => c.id === v.id)) {
        store.lookups.users.push(v);
    }

    closeUserModal();
    props.comment.assignedToId = v.id;
}

</script>

<template>

    <Transition mode="out-in">

        <div v-if="comment.showSendControls" class="flex flex-col gap-2">

            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row items-center gap-2">
                    <div class="flex flex-row items-center gap-1">
                        <Switch id="comment-user" :checked="comment.hasAssignedUser" @update:checked="() => comment.hasAssignedUser = !comment.hasAssignedUser" />
                        <Label for="comment-user" class="text-sm text-muted-foreground hover:cursor-pointer flex flex-row items-center gap-1">
                            {{ t('common.assignToUserTitle') }}
                            <Icon :icon="icons.user" class="shrink-0" />
                        </Label>
                    </div>
                    <Transition mode="out-in">
                        <Dropdown v-if="comment.hasAssignedUser" :items="store.types.users" :title="t('common.person')" :icon="icons.user" @update:modelValue="v => comment.assignedToId = v">
                            <template #empty>
                                <div class="flex flex-col items-center gap-2">
                                    <span>
                                        {{ t('common.noResultsFound') }}
                                    </span>
                                    <Button class="border-dashed w-fit" @click="createUser">
                                        {{ t('common.create') }} {{ t('common.new') }}
                                    </Button>
                                </div>
                            </template>
                        </Dropdown>
                    </Transition>
                </div>
                <Button @click="() => { comment.showSendControls = false; emits('update:open', false) }" size="sm" variant="ghost" class="text-destructive">
                    <Icon :icon="icons.xxx" />
                </Button>
            </div>

            <Textarea :placeholder="t('common.comment')" v-model="comment.text"></Textarea>

            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row gap-2 items-center">
                    <div class="flex flex-row items-center gap-1">
                        <div class="flex flex-row items-center gap-1">
                            <Switch id="comment-targetDate" :checked="comment.hasTargetDate" @update:checked="() => comment.hasTargetDate = !comment.hasTargetDate" />
                            <Label for="comment-targetDate" class="text-sm text-muted-foreground hover:cursor-pointer flex flex-row items-center gap-1">
                                {{ t('common.targetDateTitle') }}
                                <Icon :icon="icons.date" class="shrink-0" />
                            </Label>
                        </div>
                        <Transition mode="out-in">
                            <DatePicker v-if="comment.hasTargetDate" :modelValue="comment.targetDate" :title="t('common.targetDate')" @update:modelValue="v => comment.targetDate = v" />
                        </Transition>
                    </div>
                    <div class="flex flex-row items-center gap-1">
                        <div class="flex flex-row items-center gap-1">
                            <Switch id="comment-vendors" :checked="comment.hasSpecficVendors" @update:checked="() => comment.hasSpecficVendors = !comment.hasSpecficVendors" />
                            <Label for="comment-vendors" class="text-sm text-muted-foreground hover:cursor-pointer flex flex-row items-center gap-1">
                                {{ t('common.updateSpecificVendorsTitle') }}
                                <Icon :icon="icons.vendors" class="shrink-0" />
                            </Label>
                        </div>
                        <Transition mode="out-in">
                            <MultiDropdown v-if="comment.hasSpecficVendors" :modelValue="comment.vendorPackageIds" :items="props.vendors.map(x => ({ value: x.id, label: x.name }))" :title="t('common.select')" :icon="icons.vendors" @update:modelValue="v => comment.vendorPackageIds = v" />
                        </Transition>
                    </div>
                </div>
            </div>

        </div>

    </Transition>

    <Modal :open="isUserModalOpen" @update:open="v => isUserModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div class="flex flex-col grow">
                    <UserForm :item="userItem" @close="closeUserModal" @update:modelValue="v => addNewUser(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>