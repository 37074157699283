<script setup>

import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { useI18n } from 'vue-i18n'

const store = useGlobalState();
const { t } = useI18n();

import { handleFileChange } from '@/lib/utils';

const props = defineProps({
    value: Object,
    element: Object,
    readonly: {
        type: Boolean,
        default: false
    }
});

</script>

<template>
    <div class="flex flex-col items-start gap-1.5">
        <div class="flex flex-row items-center gap-1">
            <template v-if="$slots.label">
                <slot name="label"></slot>
            </template>
            <Label :for="`${element.id}-${element.uid}`" class="text-sm">{{ element.name }}</Label>
        </div>

        <Input v-if="element.type === 0" :id="`${element.id}-${element.uid}`" class="bg-white" type="text" :placeholder="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Textarea v-else-if="element.type === 1" :id="`${element.id}-${element.uid}`" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" class="w-full h-24" :placeholder="element.name"></Textarea>

        <Number v-else-if="element.type === 2" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <DatePicker v-else-if="element.type === 3" :title="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <div v-if="element.type === 4">
            <Input :id="`${element.id}-${element.uid}`" type="file" :placeholder="element.name" @change="e => handleFileChange(e, value)" @blur="e => handleFileChange(e, value)" @rest="e => handleFileChange(e, value)" :readonly="props.readonly" :disabled="props.readonly ? true : false" />
        </div>

        <div v-if="element.type === 5">
            <Switch :id="`${element.id}-${element.uid}`" :checked="value.value === '1'" @update:checked="v => value.value = (v ? '1' : '0')" :disabled="props.readonly ? true : false" />
        </div>

        <div v-if="element.type === 6">
            <VoiceRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <div v-if="element.type === 7">
            <CameraRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <div v-if="element.type === 8">
            <ScreenRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <Number v-else-if="element.type === 9" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 10" :items="store.lookups.options[store.ei('ElementType', 10)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 11" :items="store.lookups.options[store.ei('ElementType', 11)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 12" :items="store.lookups.options[store.ei('ElementType', 12)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 14" :items="store.lookups.options[store.ei('ElementType', 14)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Number v-else-if="element.type === 13" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

    </div>
</template>


<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>