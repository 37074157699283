<script setup>

import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n'

import { post, put } from '@/utils/http';

import { useGlobalState } from '@/views/_shared/stores/GlobalState';

import { icons } from '@/utils/icons';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const own = ref({
    ...props.item,
});

const emits = defineEmits(['update:modelValue', 'close']);

const isValid = computed(() => own.value.firstName && own.value.lastName && own.value.email);

const isPosting = ref(false);

const onSubmit = async () => {
    if (!isValid.value) {
        return;
    }

    if (isPosting.value) {
        return;
    }

    isPosting.value = true;

    const data = await send();
    store.refershLookups();

    isPosting.value = false;
    emits('update:modelValue', {
        old: own.value,
        new: data
    });
};

const send = async () => {
    const url = '/global/accounts/users';

    if (own.value.id) {
        const { data } = await put(url, own.value);
        return data;
    } else {
        const { data } = await post(url, own.value);
        return data;
    }
}

onMounted(() => {
    document.addEventListener('keydown', close);
    document.addEventListener('keydown', save);
});

onUnmounted(() => {
    document.removeEventListener('keydown', close);
    document.removeEventListener('keydown', save);
});

const close = (e) => {
    if (isPosting.value) {
        return;
    }

    if (e.key === 'Escape') {
        emits('close');
    }
}

const save = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
        onSubmit();
    }
}

</script>

<template>

    <div class="flex flex-col justify-center sm:items-center">
        <Card class="w-full p-4">

            <CardHeader>
                <CardTitle>
                    <div class="flex flex-row items-center justify-between">
                        <span class="flex flex-row items-center gap-2">
                            <Icon :icon="icons.user" class="shrink-0" />
                            <div class="font-medium">{{ own.id ? t('common.edit') : t('common.create') }}</div>
                        </span>
                        <ConfirmClose :isDisabled="isPosting" @close="emits('close')" />
                    </div>
                </CardTitle>
            </CardHeader>

            <CardContent class="w-full space-y-3">

                <div class="flex flex-row items-center justify-between gap-4">
                    <Input v-focus type="text" :placeholder="t('common.firstName')" v-model="own.firstName" />
                    <Input type="text" :placeholder="t('common.lastName')" v-model="own.lastName" />
                </div>
                <Input type="email" :placeholder="t('common.email')" v-model="own.email" />

                <CheckCard :isChecked="own.isAdmin" @update:modelValue="v => own.isAdmin = v" :title="t('common.adminTitle')" :description="t('common.adminDescription')" :checkIcon="icons.admin" :unCheckIcon="icons.user" />
            </CardContent>
            <CardFooter class="flexflex-row px-6 pb-6 gap-2 justify-end">
                <Button @click="onSubmit" :disabled="isPosting || !isValid">
                    <Transition mode="out-in">
                        <span v-if="!isPosting">
                            {{ own.id ? t('common.edit') : t('common.save') }}
                        </span>
                        <Icon :icon="icons.loaderCircle" v-else class="h-4 w-4 animate-spin shrink-0" />
                    </Transition>
                </Button>
            </CardFooter>
        </Card>
    </div>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>