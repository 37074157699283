<script setup>

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import TimeAgo from 'javascript-time-ago';
import { getLocale } from '@/localization/locale';

import { getTimeAgoStatusColor } from '@/lib/utils';

const tg = new TimeAgo(getLocale());

const { t } = useI18n();

const props = defineProps({
    date: {
        type: String,
    },
    label: {
        type: String,
        default: '',
    },
    format: {
        type: String,
        default: 'round',
    },
    variant: {
        type: String,
        default: 'outline',
    },
    indicateColor: {
        type: Boolean,
        default: true,
    },
});

const formattedDate = computed(() => {
    if (!props.date) {
        return '';
    }


    const d = new Date(props.date);
    const isToday = d.toDateString() === new Date().toDateString();

    return isToday ? t('common.today') : tg.format(d, props.format);
});

</script>

<template>
    <Transition mode="out-in">
        <Badge v-if="props.date" :variant="props.variant" class="w-fit flex flex-row justify-between gap-1" :class="props.indicateColor ? getTimeAgoStatusColor(props.date) : ''">
            <Transition mode="out-in">
                <span v-if="props.label">
                    {{ props.label }}
                </span>
            </Transition>
            <span>
                {{ formattedDate }}
            </span>
        </Badge>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>