<script setup>

import { onMounted, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
    items: {},
})

onMounted(() => {
    props.items.forEach(item => {
        item.values = !item.values || item.values.length === 0 ? [{ uid: uuidv4(), value: null }] : item.values;
    });
});

watch(() => props.items, (newVal, _) => {
    newVal.forEach(item => {
        item.values = !item.values || item.values.length === 0 ? [{ uid: uuidv4(), value: null }] : item.values;
    });
});


</script>

<template>
    <div v-for="(element, eIndex) in props.items" :key="eIndex" class="flex flex-col my-1.5">
        <ElementValue :element="element" />
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>