import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { getCurrentSubscription } from '@/utils/auth';

import { get } from '@/utils/http';

export const useGlobalState = defineStore('stores.globalState', () => {

    const isLoading = ref(false);
    const backgroundColor = ref('bg-white');
    const hasEnums = computed(() => Object.keys(enums.value).length > 0);

    const breadcrumb = ref([]);

    const lookupStore = ref({
        vendors: [],
        users: [],
        templates: [],
        projects: [],
        enums: {},
        options: {},
    });

    const fromLookup = (type) => lookupStore.value[type]?.map(item => ({
        value: item.id,
        label: item.name,
    })) ?? [];

    const enums = computed(() => {
        const enums = lookupStore.value.enums;
        const enumKeys = Object.keys(enums);

        return enumKeys.reduce((acc, key) => {
            acc[key] = enums[key].map(item => ({
                value: item.intId,
                label: item.text,
            }));

            return acc;
        }, {});
    });

    const types = computed(() => ({
        vendors: fromLookup('vendors'),
        users: fromLookup('users'),
        templates: fromLookup('templates'),
        components: fromLookup('components'),
        projects: fromLookup('projects'),
    }));

    const lookups = computed(() => lookupStore.value);

    function getTypeText(type, id) {
        const lookupType = types.value[type];
        if (!lookupType) {
            return '';
        }

        const lookupItem = lookupType.find(item => item.value === id);
        if (!lookupItem) {
            return '';
        }

        return lookupItem.label;
    }

    function fetchLookups(force = false) {
        if(force === true){
            refershLookups();
            return;
        }
        
        if (hasEnums.value) {
            return;
        }

        refershLookups();
    }

    function refershLookups() {

        const sub = getCurrentSubscription();
        if (sub.isBuyer) {
            get('/global/lookups')
                .then(({ data }) => {
                    lookupStore.value = data;
                })
        } else {
            get('/hi/lookups')
                .then(({ data }) => {
                    lookupStore.value = data;
                })
        }

    }

    function setLoading(value) {
        isLoading.value = value;
    }

    function setBackgroundColor(value) {
        if (!value) {
            backgroundColor.value = 'bg-white';
            return;
        }

        backgroundColor.value = value;
    }

    function setBreadcrumb(value) {
        if (!value) {
            breadcrumb.value = [];
            return;
        }

        breadcrumb.value = value;
    }

    function ev(enums, value) {
        return enums?.find(e => e.value === value)?.label;
    }

    function ei(enums, value) {
        return lookups.value.enums[enums]?.find(e => e.intId === value)?.stringId;
    }

    function lv(lookup, predicate, projection) {
        const item = lookups.value[lookup]?.find(predicate);
        if (!item) {
            return '';
        }

        return projection(item);
    }

    function tv(type, value) {
        return types.value[type]?.find(e => e.value === value)?.label;
    }

    return {
        isLoading,
        backgroundColor,
        breadcrumb,
        types,
        enums,
        lookups,
        ev,
        ei,
        lv,
        tv,
        refershLookups,
        fetchLookups,
        setLoading,
        setBackgroundColor,
        setBreadcrumb,
        getTypeText
    }
});