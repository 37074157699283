<script setup>
import { onMounted, reactive } from "vue"
import { useI18n } from 'vue-i18n'

import { post } from "@/utils/http";
import { useRouter, useRoute } from 'vue-router';
import { isUserAuthenticated } from '@/utils/auth';

import { cn } from '@/lib/utils'

import { icons } from "@/utils/icons";

import { navigateHome } from "@/utils/nav";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

onMounted(() => {
    if (isUserAuthenticated()) {
        navigateHome(router);
        return;
    }
});

const state = reactive({
    email: '',
    isLoading: false,
    message: ''
});

const onSubmit = async () => {
    if (!state.email) {
        return;
    }

    state.isLoading = true;

    post('auth/welcome', {
        email: state.email,
        returnUrl: route.query.ReturnUrl
    }).then((_) => {
        state.message = t('views.auth.login.magicLink');
    }).catch((error) => {
        console.error(error);
    }).finally(() => {
        state.isLoading = false;
    })
};

</script>

<template>
    <ScrollArea class="h-full w-full">
        <div class="flex flex-row items-center justify-center h-svh">
            <Card class="flex flex-col justify-center space-y-6 sm:w-[350px] p-4">
                <div class="flex flex-col space-y-2 text-center items-center">
                    <figure class="mb-3">
                        <AlinorLogo />
                    </figure>
                    {{ t('views.auth.login.signIn') }}
                    <Transition mode="out-in">
                        <p v-if="!state.message" class="text-sm text-muted-foreground">
                            {{ t('views.auth.login.enterYourEmail') }}
                        </p>
                    </Transition>
                </div>
                <Transition mode="out-in">
                    <Alert class="w-full text-start" v-if="state.message">
                        <Icon :icon="icons.rocket" class="shrink-0" />
                        <AlertTitle>{{ t('views.auth.login.checkYourInbox') }}</AlertTitle>
                        <AlertDescription>
                            {{ state.message }}
                        </AlertDescription>
                    </Alert>
                    <div v-else :class="cn('grid gap-6', $attrs.class ?? '')">
                        <div>
                            <div class="grid gap-2">
                                <div class="grid gap-1">
                                    <Label class="sr-only" for="email">
                                        {{ t('common.email') }}
                                    </Label>
                                    <Input @keydown.enter="onSubmit" id="email" placeholder="name@example.com" type="email" auto-capitalize="none" auto-complete="email" auto-correct="off" :disabled="state.isLoading" v-model="state.email" />
                                </div>
                                <Button @click="onSubmit" variant="default" :disabled="state.isLoading || !state.email">
                                    <Transition mode="out-in">
                                        <span v-if="!state.isLoading">
                                            {{ t('views.auth.login.signIn') }}
                                        </span>
                                        <Icon :icon="icons.loaderCircle" v-else class="h-4 w-4 animate-spin shrink-0" />
                                    </Transition>
                                </Button>
                                <span class="text-muted-foreground text-center">{{ t('common.or') }} <router-link :to="{ name: 'register' }">{{ t('views.auth.register.register') }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </Transition>
            </Card>
        </div>
    </ScrollArea>

</template>


<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
